<template>
  <div class="content">
    <div class="add_pay">
      <el-button type="text"
                 @click="add_Pay">添加充值优惠</el-button>
      <el-button type="text"
                 @click="save">保存当前充值优惠设置</el-button>
    </div>

    <div class="Addcard"
         v-for="(item,index) in num"
         :key="index">
      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>充值优惠</span>
          <el-button style="float: right; padding: 3px 0"
                     type="text"
                     @click="del(item)">删除</el-button>
        </div>
        <div class="text item">
          <el-input v-model="input[item].min_amount"
                    placeholder="请输入起送金额"></el-input>
        </div>
        <div class="text item">
          <el-input v-model="input[item].balance"
                    placeholder="请输入直接赠送金额"></el-input>
        </div>
        <div class="text item">
          <el-input v-model="input[item].integral"
                    placeholder="请输入直接赠送积分"></el-input>
        </div>
        <div class="text item">
          <el-input v-model="input[item].tour_money"
                    placeholder="请输入直接赠送抵用金"></el-input>
        </div>
      </el-card>
    </div>

    <div style="color:#409EFF;margin-bottom: 20px;">过往充值优惠</div>
    <div class="card"
         v-for="item in pay_record "
         :key="item.id">
      <el-card class="box-card">
        <div slot="header"
             class="clearfix">
          <span>充值优惠</span>
          <el-button style="float: right; padding: 3px 0"
                     type="text">操作按钮</el-button>
        </div>
        <div class="text item">起送金额:{{item.min_amount}}</div>
        <div class="text item">直接赠送金额:{{item.balance}}</div>
        <div class="text item">直接赠送积分:{{item.integral}}</div>
        <div class="text item">直接赠送抵用金:{{item.tour_money}}</div>

      </el-card>
    </div>

  </div>
</template>

<script>
import { getComplimentary, Complimentary } from '@/api/finance'
export default {
  data () {
    return {
      pay_record: null,
      num: 1,
      input: [
        {
          min_amount: null,
          balance: null,
          integral: null,
          tour_money: null
        },
        {
          min_amount: null,
          balance: null,
          integral: null,
          tour_money: null
        }
      ],
    }
  },
  created () {
    this.getComplimentary()
  },
  methods: {
    getComplimentary () {
      getComplimentary().then(res => {
        console.log(res.data.data, "充值");
        this.pay_record = res.data.data
      })
    },
    save () {
      let arr = this.input;
      let add_balance_gift_data = arr.slice(0);
      add_balance_gift_data.shift();
      // add_balance_gift_data = JSON.stringify(add_balance_gift_data)

      // console.log(add_balance_gift_data);
      let data = {
        add_balance_gift_data: add_balance_gift_data
      }
      console.log(data);
      Complimentary(data).then(res => {
        // console.log(res);
      })


    },
    add_Pay () {
      this.num++
      this.input.push({
        min_amount: null,
        balance: null,
        integral: null,
        tour_money: null
      })
    },
    del (item) {
      console.log(item);
      this.num[item]
      this.num--
      // console.log(this.num[item]);
    }
  },
}
</script>

<style lang="scss" scoped>
.content {
  .add_pay {
  }
  .card {
    display: inline-block;
    margin-right: 30px;
    .item {
      margin-bottom: 18px;
    }
    .box-card {
      width: 280px;
    }
  }
  .Addcard {
    margin-bottom: 20px;
    margin-right: 30px;
    display: inline-block;
    .item {
      margin-bottom: 18px;
    }
    .box-card {
      width: 280px;
    }
  }
}
</style>