import { request } from './request.js'
import qs from 'qs'

// 查看-充值赠送
export function getComplimentary () {
  return request({
    url: '/admin/add-balance-gift',
		method:'GET',
  })
}
// 设置充值赠送
export function Complimentary (params) {
  return request({
    url: '/admin/add-balance-gift',
		method:'POST',
    data:params
  })
}